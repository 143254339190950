<template>
  <b-card>
    <b-row>
      <!-- for classrooms -->
      <b-col
        class="category-section"
        md="3"
      >
        <h4> {{ this.$t('classes') }} </h4>
        <b-row
          class="subCategory-section"
        >
          <!-- section for current -->
          <b-col md="6">
            <h5 class="current-bc">
              {{ this.$t('currentYear') }}
            </h5>
            <b-form-group
              v-for="item in itemsList"
              :key="item"
            >
              <g-field
                field="select"
                label="arabicName"
                name="currentClass"
                :value.sync="item.currentClass"
                :dir="isRight ? 'rtl' : 'ltr'"
                :options="classrooms"
                :filter-fn="(r) => r.row === item.currentRow"
                @change="setCurrentAndNextClassData(item)"
              />
            </b-form-group>
          </b-col>
          <!-- section for next -->
          <b-col md="6">
            <h5 class="next-bc">
              {{ this.$t('nextYear') }}
            </h5>
            <b-form-group
              v-for="item in itemsList"
              :key="item"
            >
              <g-field
                label="arabicName"
                name="nextClass"
                :value.sync="item.nextClassName"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <!-- for rows -->
      <b-col
        class="category-section"
        md="4"
      >
        <h4> {{ this.$t('rows') }} </h4>
        <b-row
          class="subCategory-section"
        >
          <!-- section for current -->
          <b-col md="6">
            <h5 class="current-bc">
              {{ this.$t('currentYear') }}
            </h5>
            <b-form-group
              v-for="item in itemsList"
              :key="item"
            >
              <g-field
                field="select"
                label="arabicName"
                name="currentRow"
                :value.sync="item.currentRow"
                :dir="isRight ? 'rtl' : 'ltr'"
                :options="rows"
                @change="setCurrentAndNextLevelData(item)"
              />
            </b-form-group>
          </b-col>
          <!-- section for next -->
          <b-col md="6">
            <h5 class="next-bc">
              {{ this.$t('nextYear') }}
            </h5>
            <b-form-group
              v-for="item in itemsList"
              :key="item"
            >
              <g-field
                name="nextRow"
                :value.sync="item.nextRowName"
                disabled
              />
            </b-form-group>
          </b-col>

        </b-row>
      </b-col>

      <!-- for stages -->
      <b-col
        class="category-section"
        md="3"
      >
        <h4> {{ this.$t('stages') }} </h4>
        <b-row
          class="subCategory-section"
        >
          <!-- section for current -->
          <b-col
            md="6"
          >
            <h5 class="current-bc">
              {{ this.$t('currentYear') }}
            </h5>
            <b-form-group
              v-for="item in itemsList"
              :key="item"
            >
              <g-field
                name="currentStage"
                :value.sync="item.currentStageName"
                disabled
              />
            </b-form-group>
          </b-col>
          <!-- section for next -->
          <b-col md="6">
            <h5 class="next-bc">
              {{ this.$t('nextYear') }}
            </h5>
            <b-form-group
              v-for="item in itemsList"
              :key="item"
            >
              <g-field
                name="nextStage"
                :value.sync="item.nextStageName"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <!-- exclude Students -->
      <b-col
        class="category-section"
        md="2"
      >
        <h4
          style="margin-bottom: 65px;"
        > {{ this.$t('excludedSomeStudents') }} </h4>
        <b-row
          class="subCategory-section"
        >
          <!-- section for next -->
          <b-col md="12">
            <b-form-group
              v-for="item in itemsList"
              :key="item"
            >
              <g-field
                field="select"
                label="arabicName"
                name="excludedStudents"
                :value.sync="item.excludedStudents"
                :dir="isRight ? 'rtl' : 'ltr'"
                :options="item.studentsPerClass"
                multiple
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- operations -->
    <b-row class="d-flex justify-content-between mt-3 flex-nowrap">
      <b-col class="d-flex">
        <b-button
          variant="outline-primary"
          style="letter-spacing: 1px;"
          @click="addNewLine()"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          {{ $t('addAnotherTransferOpertation') }}
        </b-button>

        <b-button
          class="ml-1"
          variant="outline-danger"
          style="letter-spacing: 1px;"
          @click="removeLastLine()"
        >
          <feather-icon
            icon="TrashIcon"
            class="mr-50"
          />
          {{ $t('removeLastTransferOpertation') }}
        </b-button>
      </b-col>
      <b-col
        class="d-flex justify-content-end"
      >
        <b-button
          variant="relief-primary"
          style="letter-spacing: 1.5px;"
          @click="applyProcess()"
        >
          <feather-icon
            icon="PlayIcon"
            class="mr-50"
          />
          {{ $t('applyStudentSuccessProcesses') }}
        </b-button>
      </b-col>
    </b-row>

    <!-- pop-up menu for admin password -->
    <b-row>
      <b-modal
        ref="password-model"
        no-close-on-backdrop
        cancel-variant="outline-secondary"
        centered
        size="sm"
        hide-footer
        :title="$t('adminConfirmation')"
      >
        <b-row>
          <!-- password space  -->
          <b-col md="10">
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label class="admin-password"> {{ $t('password') }}</label>
              </div>
              <g-field
                v-show="helper.isPassword"
                label="Password"
                :value.sync="helper.adminPassword"
                type="password"
                placeholder="· · · · · · · · · · · ·"
              />
              <g-field
                v-show="!helper.isPassword"
                label="Password"
                :value.sync="helper.adminPassword"
                type="text"
              />
            </b-form-group>
          </b-col>
          <!-- password button for handling icon -->
          <b-col md="2">
            <b-button
              class="password-btn"
              variant="secondary"
            >
              <feather-icon
                :icon="togglePasswordIcon"
                @click="helper.isPassword = !helper.isPassword"
              />
            </b-button>
          </b-col>
        </b-row>

        <!-- confirmation button -->
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-center"
          >
            <b-button
              variant="relief-primary"
              @click="confirmOperation()"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              {{ this.$t('confirm') }}
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
    </b-row>

  </b-card>
</template>

<script>
export default {
  data() {
    return {
      educationalData: [],
      rows: [],
      classrooms: [],
      itemsList: [],

      helper: {
        isPassword: true,
        adminPassword: '',
        classCategoryId: 3
      }
    }
  },

  computed: {
    togglePasswordIcon() {
      return this.helper.isPassword ? 'EyeIcon' : 'EyeOffIcon';
    },
  },

  mounted() {
    this.addNewLine();
    this.getData();
  },

  methods: {
    isClassSelectedBefore(classId) {
      return this.itemsList.filter((x) => x.currentClass === classId).length > 1
    },

    isAllCurrentClassSectionSelected() {
      return this.itemsList.filter((x) => x.currentClass > 0).length === this.itemsList.length
    },

    isNonOperationOccured() {
      return this.itemsList.length === 0;
    },

    setCurrentAndNextClassData(item) {
      // get educational data for selected class
      const selectedlevel = this.educationalData.find((x) => x.classroomId === item.currentClass)

      // validate that current class not have next OR if it's already selected
      if (!selectedlevel.nextClassroom || this.isClassSelectedBefore(selectedlevel.classroomId)) {
        const alertMessage = !selectedlevel.nextClassroom
                           ? this.$t('classNotHaveNext', { className: selectedlevel.classroomArabicName })
                           : this.$t('classSelectedBefore', { className: selectedlevel.classroomArabicName })

        this.doneAlert({ text: alertMessage, type: 'warning', timer: 8000 });
        this.resetClass(item);
        return;
      }

      // then set next values and names for selected one
      const nextClass = this.educationalData.find((x) => x.classroomId === selectedlevel.nextClassroom)
      item.nextClass = nextClass.classroomId
      item.nextClassName = nextClass.classroomArabicName;
      item.excludedStudents = [];

      this.get({ url: `Students/getStudentsByGroupId?group=${this.helper.classCategoryId}&id=${item.currentClass}` }).then((data) => {
        item.studentsPerClass = data;
      });
    },

    setCurrentAndNextLevelData(item) {
      // if value is empty return
      if (!item.currentRow) {
        this.resetWholeLevels(item);
        return;
      }

      // get educational data for selected row
      const selectedRow = this.educationalData.find((x) => x.rowId === item.currentRow)

      // validate that current rpw have next
      if (!selectedRow.nextRow) {
        this.doneAlert({ text: this.$t('rowNotHaveNext', { rowName: selectedRow.rowArabicName }), type: 'warning', timer: 8000 });
        this.resetWholeLevels(item);
        return;
      }

      // set current values and names for selected one
      item.currentStageName = selectedRow.stageArabicName

      // then set next values and names for selected one
      const _nextRow = this.educationalData.find((x) => x.rowId === selectedRow.nextRow)
      item.nextRowName = _nextRow.rowArabicName
      item.nextStageName = _nextRow.stageArabicName
      this.resetClass(item);
    },

    resetClass(item) {
      if (item.currentClass) item.currentClass = null;
      if (item.nextClass) item.nextClass = null;
      item.nextClassName = '';
      item.studentsPerClass = [];
      item.excludedStudents = [];
    },

    resetWholeLevels(item) {
      item.currentRow = null;
      item.nextRowName = '';
      item.currentStageName = '';
      item.nextStageName = '';
      this.resetClass(item);
    },

    addNewLine() {
      this.itemsList.push({
        currentClass: null,
        nextClass: null,
        excludedStudentsIds: null,
        excludedStudents: [],
        studentsPerClass: []
      });
    },

    removeLastLine() {
      this.itemsList.pop();
    },

    getData() {
      this.get({ url: 'Stages/getFullEducationalData' }).then((data) => {
        this.educationalData = data;
      })
      this.get({ url: 'Rows/getRowsLookupWithEducationlIds' }).then((data) => {
        this.rows = data
      })
      this.get({ url: 'Classrooms/getClassroomsLookupWithEducationlIds' }).then((data) => {
        this.classrooms = data
      })
    },

    applyProcess() {
      // if no operation occured inform user
      if (this.isNonOperationOccured()) {
        this.doneAlert({ text: this.$t('noStudentSuccessOperationCreated'), type: 'warning' });
        return
      }

      // make sure that all data is ok
      if (!this.isAllCurrentClassSectionSelected()) {
        this.doneAlert({ text: this.$t('notAllCurrentClassSectionSelected'), type: 'warning', timer: 8000 });
        return
      }

      // show warning alert
      this.confirmAction(
        {
          title: this.$t('applyStudentSuccessProcesses'),
          text: this.$t('studentSuccessAlert1'),
          confirmText: this.$t('yes,sure')
        },
        () => {
          this.helper.isPassword = true
          this.helper.adminPassword = null
          this.$refs['password-model'].show();
        }
      );
    },

    confirmOperation() {
      // if he choose to make changes ask him for ADMIN password
      this.get({ url: `Users/checkCurrentPassword?password=${this.helper.adminPassword}` }).then((isValid) => {
        // if its valid apply operation
        if (isValid) {
          this.$refs['password-model'].hide();
          this.confirmAction(
            {
              title: this.$t('applyStudentSuccessProcesses'),
              text: this.$t('studentSuccessAlert2'),
              confirmText: this.$t('agree')
            },
            () => {
              // prepare exclueded students
              this.setExcludedStudents();
              this.update({
                  url: 'Students/applyStudentSuccess',
                  data: this.serializeObject()
                }).then((data) => {
                if (data) {
                  this.doneAlert({ text: this.$t('studentSucessOperationAppliedSuccessfully') });
                  this.itemsList = [];
                  this.addNewLine();
                } else {
                  this.doneAlert({ text: this.$t('somethingWrongWhileApplyingStudentSucessOperation'), type: 'error', timer: 4000 });
                }
              });
            }
          );

          // other wise inform user that is invalid
        } else {
          this.doneAlert({ text: this.$t('invalidPassword'), type: 'error' });
        }
      });
    },
    setExcludedStudents() {
      this.itemsList.forEach(successLine => {
        successLine.excludedStudentsIds = [];
        if (successLine.excludedStudents) {
          successLine.excludedStudents.forEach(element => {
            successLine.excludedStudentsIds.push(element.id);
          });
        }

        successLine.excludedStudentsIds = successLine.excludedStudentsIds.length > 0
                                        ? successLine.excludedStudentsIds.join(',')
                                        : null;
      });
    },
    serializeObject() {
      return {
        transfers: this.itemsList.map(item => ({
          currentClass: item.currentClass,
          nextClass: item.nextClass,
          excludedStudentsIds: item.excludedStudentsIds
        }))
      };
    }
  }
}
</script>

<style>
.category-section h4,
.category-section h5 {
  text-align: center;
  letter-spacing: 1.2px;
  padding: 10px 5px;
  border-radius: 5px;
}
.category-section h4 {
  background-color: antiquewhite;
}
.category-section h5 {
  margin-bottom: 20px;
}
.subCategory-section .form-group{
  margin-bottom: 5px;
}
.current-bc {
  background-color: lavenderblush;
}
.next-bc {
  background-color: #e5f3f3;
}
.admin-password {
  font-size: 14px;
  letter-spacing: 1.5px;
  margin-bottom: 7px;
}
.password-btn {
  margin-top: 27px;
  margin-left: -27px;
}
</style>
